<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-card color="light">
          <ion-card-content>
            <ion-row>
              <ion-text style="font-weight:bold; font-size: 137%">
                Productores
              </ion-text>
            </ion-row>
            <ion-row style="margin-top: 8px">
              <ion-label style="margin-left: 7px">
                Buscar
              </ion-label>
              <ion-searchbar placeholder="Nombre o Email"
                @ionChange="filtroBusqueda = $event.target.value"></ion-searchbar>
            </ion-row>
            <ion-row style="margin-top: 8px">
              <ion-label style="margin-left: 8px" v-if="isAdminRegional">Regional</ion-label>
            </ion-row>
            <ion-row style="margin-top: 8px">
              <ion-label style="margin-left: 8px; font-weight: bold;" v-if="isAdminRegional">{{ admin_user.regional.nombre
              }}</ion-label>
            </ion-row>

            <ion-item style="margin-left: 8px;" color="light" v-if="!isAdminRegional">
              <ion-label position="floating">
                Regional
              </ion-label>
              <ion-select :value="filtroRegional" @ionChange="filtroRegional = $event.target.value">
                <ion-select-option v-for="reg in regionales" :value="reg" :key="reg._id">
                  {{ reg.nombre }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <div class="ion-margin-top">
              <ion-label style="margin-left: 8px">Ordenar por</ion-label>
              <ion-radio-group v-model="sortBy">
                <ion-grid>
                  <ion-row>
                    <ion-col size="auto">
                      <ion-label style="margin-right: 8px">Nombre</ion-label>
                      <ion-radio @click="sortBy = 'Nombre'" value="Nombre"></ion-radio>
                    </ion-col>
                    <ion-col size="auto">
                      <ion-label style="margin-right: 8px">Email</ion-label>
                      <ion-radio @click="sortBy = 'Email'" value="Email"></ion-radio>
                    </ion-col>
                    <ion-col v-if="
                      /*
                      !isAdminRegional &&
                        (filtroRegional.nombre === '' ||
                          filtroRegional.nombre === 'Todas')*/
                      false
                      " size="auto">
                      <ion-label style="margin-right: 8px">Regional</ion-label>
                      <ion-radio @click="sortBy = 'Regional'" value="Regional"></ion-radio>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-radio-group>
            </div>
            <ion-list-header></ion-list-header>
            <ion-list lines="full" v-for="prod in productores" :key="prod._id">
              <ion-item button="true" type="button" class="itemBorder" @click="goToProductorBoard(prod)">
                <ion-grid fixed>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto" style="margin-right: 8px">
                      <ion-icon :md="icons.person" :ios="icons.person" size="large"></ion-icon>
                    </ion-col>
                    <ion-col>
                      <ion-row style="margin-top: 4px;">
                        <ion-text style="font-weight:bold; margin-bottom: 4px;" class="ion-text-capitalize">
                          {{ prod.display_name }}
                        </ion-text>
                      </ion-row>
                      <ion-row>
                        <ion-text style="font-style: italic; margin-bottom: 4px;">
                          {{ prod.email }}
                        </ion-text>
                      </ion-row>
                      <ion-row style="margin-bottom: 4px;">
                        <ion-text v-if="!isAdminRegional &&
                          (filtroRegional.nombre === '' ||
                            filtroRegional.nombre === 'Todas')
                          ">
                          {{ prod.regional ? prod.regional.nombre : "-" }}
                        </ion-text>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                  <div style="margin-bottom: 8px;"></div>
                </ion-grid>
              </ion-item>
            </ion-list>
            <ion-row class="ion-justify-content-end ion-margin-top">
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="modalImport = true">
                Importar Datos de Lotes de Productores
              </ion-button>
            </ion-row>
            <ion-row class="ion-justify-content-end" v-if="productores && productores.length">
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="modalExcel = true">
                Exportar Datos de Lotes de Productores
              </ion-button>

            </ion-row>
            <ion-row class="ion-float-end">
              <ion-col size="auto">
                <ion-button @click="page--" size="small" :disabled="page === 1"
                  style="--background: rgba(var(--ion-color-light-rgb), 0.625); --color: black;">
                  &#60;
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button @click="page++" size="small" :disabled="lastPage"
                  style="--background: rgba(var(--ion-color-light-rgb), 0.625); --color: black;">
                  &#62;
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
        <ion-modal id="modal" :is-open="modalExcel" @didDismiss="modalExcel = false" :backdropDismiss="false">
          <ion-card>
            <ion-card-header>
              Exportar Datos de Lotes de Productores
            </ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label :position="selectedCampania ? 'stacked' : 'floating'">
                  Campaña
                </ion-label>
                <ion-select :value="selectedCampania" :selected-text="selectedCampania && selectedCampania.nombre
                  ? selectedCampania.esActual
                    ? '*' + selectedCampania.nombre.toUpperCase()
                    : selectedCampania.nombre
                  : undefined
                  " @ionChange="selectedCampania = $event.target.value">
                  <ion-select-option v-for="camp in campanias" :value="camp" :key="camp._id">
                    {{
                      camp.esActual
                      ? "*" + camp.nombre.toUpperCase()
                      : camp.nombre
                    }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-row class="ion-float-right">
                <ion-col>
                  <ion-button fill="outline" class="ion-margin-top" color="medium" @click="modalExcel = false">
                    Cancelar
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button class="ion-float-right ion-margin-top" @click="showFilas()">
                    Exportar
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-modal>
        <ion-modal id="modal" :is-open="modalImport" @didDismiss="modalImport = false" :backdropDismiss="false">
          <ion-card>
            <ion-card-header>Importar Datos de Lotes</ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label :position="selectedCampania ? 'stacked' : 'floating'">
                  Campaña
                </ion-label>
                <ion-select :value="selectedCampania" :selected-text="selectedCampania && selectedCampania.nombre
                  ? selectedCampania.esActual
                    ? '*' + selectedCampania.nombre.toUpperCase()
                    : selectedCampania.nombre
                  : undefined
                  " @ionChange="selectedCampania = $event.target.value">
                  <ion-select-option v-for="camp in campanias" :value="camp" :key="camp._id">
                    {{
                      camp.esActual
                      ? "*" + camp.nombre.toUpperCase()
                      : camp.nombre
                    }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-label :position="selectedOrigen ? 'stacked' : 'floating'">
                  Origen
                </ion-label>
                <ion-select :value="selectedOrigen" :selected-text="selectedOrigen && selectedOrigen.nombre
                  ? selectedOrigen.nombre
                  : undefined
                  " @ionChange="selectedOrigen = $event.target.value">
                  <ion-select-option v-for="orig in origenes" :value="orig" :key="orig._id">
                    {{ orig.nombre }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-row class="ion-align-items-center">
                <ion-col size="auto">
                  <ion-button color="tertiary" @click="fileInput.click()">Subir Archivo</ion-button>
                </ion-col>
                <ion-col>
                  <ion-text style="white-space:normal">{{ file && file.name ? file.name : "-" }}</ion-text>
                </ion-col>
              </ion-row>
              <ion-row class="ion-float-right">
                <ion-col>
                  <ion-button fill="outline" class="ion-margin-top" color="medium" @click="modalImport = false">
                    Cancelar
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button class="ion-float-right ion-margin-top" @click="importExcel()">
                    Importar
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-modal>
        <ion-modal id="modal1" :is-open="modalProgressBar" @didDismiss="modalProgressBar = false"
          :backdropDismiss="false">
          <ion-card>
            <ion-card-header>
              Se esta generando el archivo de Excel. Por favor espere...
            </ion-card-header>
            <ion-card-content>
              <ion-progress-bar :value="progress"></ion-progress-bar>
            </ion-card-content>
          </ion-card>
        </ion-modal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonText,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonRow,
  IonCol,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonList,
  IonListHeader,
  IonPage,
  IonRadio,
  IonModal,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonIcon,
  alertController,
  IonGrid,
  IonProgressBar
} from "@ionic/vue";
import { mapActions, mapState } from "vuex";
import { ref } from 'vue';
import http from "@/api/http";
import { Regionales, Campanias } from "@/api";
import { person } from "ionicons/icons";
import dateFormat from "@/utils/dateFormat";
import readXlsFile from "read-excel-file"

export default {
  components: {
    IonGrid,
    IonContent,
    IonText,
    IonButton,
    IonSearchbar,
    IonList,
    IonListHeader,
    IonItem,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonPage,
    IonIcon,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonRow,
    IonCol,
    IonProgressBar
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    error: "",
    sortBy: "Nombre",
    modalExcel: false,
    modalImport: false,
    modalProgressBar: false,
    filtroRegional: { _id: 0, nombre: "" },
    filtroBusqueda: "",
    isAdminRegional: false,
    productores: [],
    regionales: [],
    selectedCampania: {},
    selectedOrigen: {},
    campaniaTxt: "",
    campanias: [],
    origenes: [],
    progress: ref(0),
    limit: 10,
    skip: 0,
    page: 1,
    totalItems: 0,
    file: {},
    fileContent: {},
    fileInput: {},
    xlsType: "application/vnd.ms-excel",
    xlsxType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icons: {
      person: person
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapState(["admin_user"]),
    totalItemsNow() {
      if (this.productores.length && this.productores.length > 0) {
        return this.productores.length;
      }
      return 0;
    },
    lastPage() {
      let roundUppedTotalItems = this.totalItems;
      if (this.totalItems % 10 !== 0) {
        roundUppedTotalItems = parseInt(this.totalItems / 10, 10) * 10 + 10;
      }
      return this.page * this.limit === roundUppedTotalItems;
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
    this.fileInput = document.createElement('input');
    this.fileInput.type = 'file';
    this.fileInput.accept = this.xlsType + "," + this.xlsxType;
    this.fileInput.onchange = async (e) => {
      this.file = e.target.files[0];
      this.fileContent = await readXlsFile(e.target.files[0]);
    };
    setInterval(() => {
      if (this.modalProgressBar) {
        if (this.progress <= 0.25) {
          this.progress += 0.01;
        } else {
          setInterval(() => {
            if (this.progress <= 0.75) {
              this.progress += 0.01;
            }
          }, 7000);
        }
      }
    }, 300);
  },

  watch: {
    async filtroBusqueda() {
      this.page = 1;
      this.totalItems = 0;
      await this.getProductores();
    },
    async filtroRegional() {
      this.page = 1;
      this.totalItems = 0;
      await this.getProductores();
    },
    async sortBy() {
      this.page = 1;
      this.totalItems = 0;
      await this.getProductores();
    },
    modalImport(val) {
      if (val === false) {
        this.selectedCampania = {};
        this.selectedOrigen = {};
        this.file = {};
        this.fileContent = {};
        this.fileInput = document.createElement('input');
        this.fileInput.type = 'file';
        this.fileInput.accept = this.xlsType + "," + this.xlsxType;
        this.fileInput.onchange = async (e) => {
          this.file = e.target.files[0];
          this.fileContent = await readXlsFile(e.target.files[0]);
        };
      } else {
        this.selectedCampania = this.campanias.filter(
          c => c.esActual === true
        )[0];
      }
    },
    async page(val) {
      this.skip = this.limit * (val - 1);
      await this.getProductores();
    },
    "$route.query.reload": {
      handler: async function (val) {
        if (val === "true") {
          await this.dataSync();
        }
      },
      deep: true,
      immediate: true
    },
    modalExcel(val) {
      if (val === false) {
        this.selectedCampania = {};
      } else {
        this.selectedCampania = this.campanias.filter(
          c => c.esActual === true
        )[0];
      }
    }
  },

  methods: {
    ...mapActions(["getMe"]),
    async dataSync() {
      this.isAdminRegional =
        this.admin_user.roles[0] === "ADMIN_REGIONAL" ? true : false;
      if (this.isAdminRegional) {
        // Seteo los datos de la regional del usuario
        this.filtroRegional._id = this.admin_user.regional._id;
        this.filtroRegional.nombre = this.admin_user.regional.nombre;
        // Obtengo los productores de la regional del usuario
        await this.getProductoresRegional();
      } else {
        await this.getRegionales();
        await this.getProductores();
      }
      this.getOrigenes();
      this.campanias = await this.getCampanias();
    },

    async getCampanias() {
      const { data, error } = await Campanias.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Campañas.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },
    async getOrigenes() {
      try {
        const { data } = await http.get("/api/get-origenes");
        this.origenes = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getProductores() {
      try {
        const { data } = await http.get("/api/find-personas-filtradas", {
          params: {
            search: this.filtroBusqueda,
            sortBy: this.sortBy,
            limit: this.limit,
            skip: this.skip,
            totalCount: this.totalItems,
            reg: this.filtroRegional._id,
          }
        });
        this.productores = data[0];
        if (data[1]) {
          this.totalItems = data[1];
        }
      } catch (error) {
        const alert = await alertController.create({
          header: "Error",
          message: error.error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
    },

    async getProductoresRegional() {
      try {
        const { data } = await http.get("/api/find-socios-regional", {
          params: {
            search: this.filtroBusqueda,
            sortBy: this.sortBy,
            limit: this.limit,
            skip: this.skip,
            totalCount: this.totalItems,
            userId: this.admin_user._id
          }
        });
        this.productores = data[0];
        if (data[1]) {
          this.totalItems = data[1];
        }
      } catch (error) {
        console.log('ENTRO UN ERROR');
        const alert = await alertController.create({
          header: "Error",
          message: error.error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({
        populate: "regional",
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.regionales = data;
      this.regionales.unshift({ _id: 0, nombre: "Todas" });
    },

    goToLotesEquivalencias(error) {
      this.$store.dispatch('mandarData', { error, archivo: this.file.name, origen: this.selectedOrigen, rutaAnterior: this.$route.path, idProductor: '' });
      this.$router.push({ name: "persona-lotes-equivalencias" });
    },

    async showFilas() {
      const data = await http.get("/api/get-filas-xls", {
        params: {
          idPersona: undefined,
          idCampania: this.selectedCampania._id,
          nombreEmail: this.filtroBusqueda,
          regional: this.filtroRegional
        }
      });
      this.campaniaTxt = this.selectedCampania
        ? this.selectedCampania.nombre + "_"
        : "";
      const alert = await alertController.create({
        header: "Exportar datos",
        message: `${data.message}\n ¿Confirma la exportacion?`,
        buttons: [
          {
            text: "Si",
            handler: async () => {
              this.modalExcel = false;
              this.modalProgressBar = true;
              await this.generateExcel(data.data);
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    async generateExcel(id) {
      const { data } = await http.get("/api/get-xls-lotes-persona", {
        params: {
          id
        }
      });
      this.progress = 1;
      const mimeType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileName =
        "Informe_Lotes_" +
        this.campaniaTxt +
        "_" +
        dateFormat(new Date(), "dd-MM-yyyy") +
        "_-_" +
        dateFormat(new Date(), "HH-mm") +
        ".xlsx";
      fetch(`data:${mimeType};base64,${data}`)
        .then(res => res.blob())
        .then(blob => new File([blob], fileName, { type: mimeType }))
        .then(doc => {
          const url = (window.URL || window.webkitURL).createObjectURL(doc);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          return url;
        });
      this.progress = 0;
      this.modalProgressBar = false;
    },

    beautifyErrors(errors) {
      const errorList = JSON.parse(errors);
      let beautifiedErrors = "";
      for (const item of errorList) {
        beautifiedErrors += `Errores en fila ${item.fila}, sección ${item.seccion}:\n`;
        for (const error of item.errores) {
          beautifiedErrors += `- ${error.message}\n`;
        }
        beautifiedErrors += `\n`
      }
      return beautifiedErrors;
    },

    beautifyErrorsHTML(errors) {
      const errorList = JSON.parse(errors);
      let beautifiedErrors = "";
      for (const item of errorList) {
        beautifiedErrors += `<li><strong>Errores en fila ${item.fila}, sección ${item.seccion}:</strong><ul>`;
        for (const error of item.errores) {
          beautifiedErrors += `<li>${error.message}</li>`;
        }
        beautifiedErrors += "</ul></li>";
      }
      return beautifiedErrors;
    },

    generateErrorTxt(data) {
      const mimeType = "text/javascript";
      const fileName =
        "Errores_Importacion_" +
        dateFormat(new Date(), "dd-MM-yyyy") +
        "_-_" +
        dateFormat(new Date(), "HH-mm") +
        ".txt";
      // eslint-disable-next-line no-undef
      fetch(`data:${mimeType};JSON,${this.beautifyErrors(data.error)}`)
        .then(res => res.blob())
        .then(blob => new File([blob], fileName, { type: mimeType }))
        .then(doc => {
          const url = (window.URL || window.webkitURL).createObjectURL(doc);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          return url;
        });
    },

    async importExcel() {
      if (Object.keys(this.selectedOrigen).length === 0) {
        const alert = await alertController.create({
          header: "Debe seleccionar un Origen",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      if (this.file.name !== undefined) {
        if (this.file.type === this.xlsType || this.file.type === this.xlsxType) {
          if (this.fileContent.length !== 0) {
            const postData = {
              fileType: this.file.type,
              fileContent: this.fileContent,
              campania: this.selectedCampania,
              originImport: this.selectedOrigen._id,
              user: this.persona
            };
            try {
              await http.post('/api/post-xls-lotes-persona', postData);
              const alert = await alertController.create({
                header: "Importación de datos",
                message: "Se han cargando los datos del archivo con exito",
                buttons: [
                  {
                    text: "Aceptar"
                  }
                ]
              });
              this.file = {};
              this.fileContent = {};
              await this.dataSync();
              this.modalImport = false;
              return alert.present();
            } catch (error) {
              if (error.status === 500) {
                const alert = await alertController.create({
                  header: "Error con el archivo",
                  message: error.error,
                  buttons: [
                    {
                      text: "Aceptar"
                    }
                  ]
                });
                return alert.present();
              }
              else {
                const alert = await alertController.create({
                  header: "Error de validación de datos",
                  message: "Los datos ingresados no son correctos o están incompletos",
                  buttons: [
                    {
                      text: "Detalles",
                      handler: async () => {
                        this.goToLotesEquivalencias(error);
                      }
                    },
                    {
                      text: "Aceptar"
                    }
                  ]
                });
                return alert.present();
              }
            }
          } else {
            const alert = await alertController.create({
              header: "Error de verificación de archivos",
              message: "No se pudo recuperar el contenido del archivo",
              buttons: [
                {
                  text: "Aceptar"
                }
              ]
            });
            this.file = {};
            this.fileContent = {};
            return alert.present();
          }
        } else {
          const alert = await alertController.create({
            header: "Error de validación de archivos",
            message: "El tipo de archivo es incorrecto. Tiene que ser .xls o xlsx",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          this.file = {};
          this.fileContent = {};
          return alert.present();
        }
      } else {
        const alert = await alertController.create({
          header: "Error de validación de archivos",
          message: "No se ha seleccionado ningún archivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        this.file = {};
        this.fileContent = {};
        return alert.present();
      }
    },

    goToProductorBoard(productor) {
      this.$router.push({
        path: `/productores/${productor._id}/lotes`
      });
      //window.location.reload();
    },
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.negrita {
  color: black;
  font-size: 125%;
  margin-left: 0px;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}

ion-card {
  margin-top: 16px;
}
</style>
